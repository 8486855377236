import React, {useState, useRef} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import useMediaQuery from '@mui/material/useMediaQuery'

import {connect} from 'react-redux'

import * as Yup from 'yup'
import {withFormik, Form, Field} from 'formik'

import {makeGetVenueById} from '../../selectors'

import {update, showAlert} from '../../actions'

import {defaultAddress, defaultLinks} from '../../helpers'

import { 
  sentenceCase,
  getTopAddress,
  getBottomAddress,
  snakeCaseObject
} from '../../utils'


import IconButton from '@mui/material/IconButton'

import FacebookIcon from '@mui/icons-material/Facebook'
import YouTubeIcon from '@mui/icons-material/YouTube'
import InstagramIcon from '@mui/icons-material/Instagram'

import {FaXTwitter} from 'react-icons/fa6'
import {IoLogoTiktok} from 'react-icons/io5'



import { 
  Errors,
  TagField,
  FormTitle,
  AspectBox,
  FormButton,
  ImageField,
  CloseButton,
  FormikTipTap,
  FormikTextField,
  ThreeDotProgress,
  GooglePlacesField,
  FormikPhoneNumberField
} from '../generics'




const cssStyles = ({isExtraSmallScreen}) => ({
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${isExtraSmallScreen ? '40px' : '60px'};

    form {
      width: 100%;
      max-width: 420px;
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      flex-direction: column;
      padding-top: 20px;
      gap: 40px;

      section {
        width: 100%;
        padding: 0px;
      }

      section:last-of-type {
        max-width: 420px;
      }
    }
  `,
  imageContainer: css`
    width: 100%;
    max-width: 420px;
  `,
  fieldContainer: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 420px;
  `,
  addressContainer: css`
    background-color: rgba(62, 166, 255, 0.1);
    padding: 15px;
    border-radius: 5px;
    border: 2px solid #dedede;

    strong {
      font-weight: 500;
    }
  `,
  linksContainer: css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
  `,
  linksIconsContainer: css`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  `,
  fieldLabel: css`
    h4 {
      margin-top: 15px;
      margin-bottom: 0;
    }

    p {
      margin-bottom: 10px;
    }
  `
})




const linkIcons = {
  instagram: <InstagramIcon />,
  tiktok: <IoLogoTiktok />,
  x: <FaXTwitter />,
  youtube: <YouTubeIcon />,
  facebook: <FacebookIcon />
}





const VenueForm = props => {

  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))

  const styles = cssStyles({isExtraSmallScreen})


  const linksRef = useRef()

  const [link, setLink] = useState('instagram')


  const {
    venue,
    close,
    values,
    errors,
    touched,
    handleBlur,
    isSubmitting,
    setFieldValue,
    setFieldError
  } = props


  const image = venue?.attributes.image


  const isAddressEmpty = Object.values(values.address).some(field => !Boolean(field))



  const trimValue = (value, field) => e => {
    handleBlur(e)


    if (typeof value === 'string' || value instanceof String) {
      const trimmed = value.trim()

      if (trimmed !== value && values[field]) {
        setFieldValue(field, trimmed)
      }
    }
  }



  const selectLink = label => () => {
    setLink(label)

    if (linksRef.current) {
      linksRef.current.focus()
    }
  }



  const label = () => {
    switch (link) {
      case 'instagram':
        return 'Instagram'
      case 'x':
        return 'X (previously Twitter)'
      case 'youtube':
        return 'YouTube'
      case 'facebook':
        return 'Facebook'
      case 'tiktok':
        return 'TikTok'
      default: 
        return ''
    }
  }





  return (
    <div css={styles.root}>
      <CloseButton onClick={close} />

      <Form autoComplete='off'>

        <section>
          <div css={styles.imageContainer}>
            <AspectBox>
              <ImageField 
                image={image} 
                fieldValue={values.image}
                setFieldValue={setFieldValue} 
                setFieldError={setFieldError}
              />
            </AspectBox>
          </div>
        </section>


        <section>
          <FormTitle title='Edit Venue' />

          <Errors errors={errors?.unmatched} />

          <div css={styles.fieldContainer}>
            <Field
              fullWidth 
              name='location'
              label='Name / Location'
              variant='filled'
              component={GooglePlacesField}
            />


            <div css={styles.addressContainer}>
              {isAddressEmpty && 
                `Once a location is selected from the dropdown above, how it will be
                 displayed on your band page will be shown here.`
              }

              {!isAddressEmpty &&
                <>
                  <p><strong>{values.location}</strong></p>

                  <p>{getTopAddress(values.address)}</p>

                  <p>{getBottomAddress(values.address)}</p>
                </>
              }
            </div>


            <Field
              name='website'
              label='Website'
              variant='filled'
              fullWidth
              component={FormikTextField}
              placeholder='Website URL'
              onBlur={trimValue(values.website, 'website')}
            />


            <Field
              name='email'
              label='Contact Email'
              variant='filled'
              fullWidth
              component={FormikTextField}
              placeholder='contact@ckord.com'
            />


            <Field 
              name='phoneNumber' 
              variant='filled'
              component={FormikPhoneNumberField} 
            />


            <Field
              variant='filled'
              component={FormikTipTap}
              name='description'
              label='Description'
              helperText={
                new Intl.NumberFormat().format(3500 - values.description.length).toString() + ' characters left'
              }
            />


            <div css={styles.linksContainer}>
              <div css={styles.fieldLabel}>
                <h4>Links</h4>
                <p>Click the icons below to add a link</p>
              </div>

              <div css={styles.linksIconsContainer}>
                {Object.keys(linkIcons).map(key => (
                  <IconButton
                    key={key}
                    size={isExtraSmallScreen ? 'medium' : 'large'} 
                    onClick={selectLink(key)}
                    sx={{
                      border: link === key ? '2px solid #000' : '2px solid #E0E3E7'
                    }}
                  >
                    {linkIcons[key]}
                  </IconButton>
                ))}
              </div>


              <Field
                name={'links.' + link}
                error={touched.links?.[link] && Boolean(errors?.links?.[link])}
                label={label()}
                fullWidth
                variant='filled'
                component={FormikTextField}
                helperText={(touched.links?.[link] && errors?.links?.[link])}
                placeholder={sentenceCase(link) + ' URL'}
              />
            </div>



            <Field
              name='tags'
              label='Tags'
              variant='filled'
              component={TagField}
              placeholder='Max of 5'
              fullWidth
            />



            <FormButton 
              type='submit' 
              variant='contained'
              color='primary'
              disabled={isSubmitting}
            >
              {isSubmitting ? <ThreeDotProgress color='#fff' radius={5} />  : 'Update'}
            </FormButton>

          </div>
        </section>
      </Form>
    </div>
  )
}




const EnhancedVenueForm = withFormik({
  mapPropsToValues: ({venue}) => {
    const {
      name,
      description,
      website,
      email,
      phoneNumber,
      address,
      coordinates,
      tags,
      links
    } = venue ? venue.attributes : {}


    return {
      name: name || '',
      email: email || '',
      phoneNumber: phoneNumber || '',
      coordinates: coordinates ? coordinates.join(' ') : '',
      location: name || '',
      location_service: '',
      location_service_id: '',
      address: address || defaultAddress,
      links: {...defaultLinks, ...links},
      description: description || '',
      tags: tags || [],
      website: website || ''
    }
  },


  validationSchema: Yup.object().shape({
    location: Yup.string().required('required').max(60),
    email: Yup.string().email('Invalid email').max(255),
    links: Yup.object().shape({
      x: Yup.string().matches(/twitter.com\//i, 'must be valid Twitter URL'),
      youtube: Yup.string().matches(/youtube.com\//i, 'must be valid YouTube URL'),
      instagram: Yup.string().matches(/instagram.com\//i, 'must be valid Instagram URL'),
      facebook: Yup.string().matches(/facebook.com\//i, 'must be valid Facebook URL'),
      tiktok: Yup.string().matches(/tiktok.com\//i, 'must be valid TikTok URL'),
    }),
  }),


  handleSubmit: (values, formikBag) => {

    const {close, venue, update, showAlert} = formikBag.props


    const {setSubmitting, setErrors, setFieldError} = formikBag


    // Add the country code to the phone number if the phone number is 
    // a US number. This is done because on the backend, certain phone numbers
    // (e.g. (718) 224-2923) throw an error if they don't have a country code
    if (values.phoneNumber && values.phoneNumber.charAt(0) === '(') {
      values.phoneNumber = '+1 ' + values.phoneNumber
    }


    // If the name does not match the name that Google returns, then throw an error
    if (values.name !== values.location) {
      if (values.location_service_id === '') {
        setFieldError('unmatched', ['For the Name / Location field, you must select a value from the dropdown'])
        setSubmitting(false)
        return
      } else {
        values.name = values.location
      }
    }


    const url = '/venues/' + venue.id

    const options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }


    let data = snakeCaseObject(values)

    data.tags = JSON.stringify(data.tags)
    data.links = JSON.stringify(data.links)
    data.address = JSON.stringify(data.address)


    update(url, 'VENUE', data, options).then(response => {
      showAlert('Information updated', 'success')
      close()
    })
    .catch(error => {
      setSubmitting(false)
      setErrors(error.apiFormErrors(values))
    })
  }
})(VenueForm)



const makeMapStateToProps = () => {
  const getVenueById = makeGetVenueById()

  const mapStateToProps = (state, props) => {
    const id = props.venueId

    return {
      venue: id ? getVenueById(state, id) : null,
    }
  }

  return mapStateToProps
}



const actions = {update, showAlert}


export default connect(makeMapStateToProps, actions)(EnhancedVenueForm)


