import {useEffect} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import {useEditor, EditorContent, FloatingMenu, BubbleMenu} from '@tiptap/react'

import StarterKit from '@tiptap/starter-kit'
import HardBreak from '@tiptap/extension-hard-break'
import Underline from '@tiptap/extension-underline'

import {IconButton, Tooltip, Box} from '@mui/material'

import ClearIcon from '@mui/icons-material/Clear'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS'




const styles = {
  root: css`
    width: 100%;
  `,
  label: css`
    display:inline-block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 5px;
  `,
  container: css`
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: rgb(244, 245, 251);
  `,
  toolbar: css`
    display: flex;
    justify-content: center;
    gap: 8px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ddd;
    padding: 8px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  `,
  error: css`
    color: #d32f2f;
    margin-left: 14px;
    margin-top: 3px;
    margin-right: 14px;
  `
}



const editorStyles = css`
  .ProseMirror {
    padding: 16px;
    font-size: 16px;
    line-height: 1.5;
    box-sizing: border-box;
    min-height: calc(16px * 1.5 * 15);
    outline: none;
    border: 1px solid transparent;
  }

  .ProseMirror:focus {
    border: 1px solid rgb(62, 166, 255);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    outline: none;
  }

  .ProseMirror p:not(ul p):not(li p) {
    margin-bottom: 16px;
  }
`


const CustomHardBreak = HardBreak.extend({
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        const {state} = this.editor
        const {$from} = state.selection

        // Check if we're at the beginning of a list item
        if (
          this.editor.isActive('listItem') &&
          $from.parentOffset === 0 // Cursor is at the start of the list item
        ) {
          // Lift the current list item out of the list (convert it to a paragraph)
          return this.editor.commands.liftListItem('listItem')
        }

        // Check if we're inside an ordered or bullet list
        if (this.editor.isActive('orderedList') || this.editor.isActive('bulletList')) {
          const isEmpty = this.editor.getJSON().content
            ?.find((node) => node.type === 'listItem')
            ?.content?.[0]?.text === ''

          // If the current list item is empty, exit the list
          if (isEmpty) {
            return this.editor.commands.liftListItem('listItem') // Exit the list
          }

          // Otherwise, create a new list item
          return this.editor.chain().createParagraphNear().run()
        }

        return this.editor.commands.setHardBreak()
      }
    }
  }
})




const FormikTipTap = ({field, form, label, helperText, ...props}) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        // hardBreak: false,
        bold: {
          keepMarks: true,
          keepAttributes: false
        },
        bulletList: {
          keepMarks: true,
          keepAttributes: false
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false
        },
        heading: {
          levels: [1, 2, 3]
        }
      }),
      Underline,
      // CustomHardBreak
    ],
    content: field.value, // Initial value from Formik
    onUpdate: ({editor}) => {
      const content = editor.getHTML()
      form.setFieldValue(field.name, content) // Update Formik's field
    }
  })


   if (!editor) return null



  return (
    <div css={styles.root}>
      <label css={styles.label}>
        {label}
      </label>
      
      <div css={styles.container}>

        {/* Persistent Toolbar */}
        <div css={styles.toolbar} aria-label='Text formatting toolbar'>
          <Tooltip title='Bold'>
            <IconButton
              onClick={e => {
                e.preventDefault() // Prevent form submission
                editor.chain().focus().toggleBold().run()
              }}
              color={editor.isActive('bold') ? 'primary' : 'default'}
            >
              <FormatBoldIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title='Italic'>
            <IconButton
              onClick={e => {
                e.preventDefault()
                editor.chain().focus().toggleItalic().run()
              }}
              color={editor.isActive('italic') ? 'primary' : 'default'}
            >
              <FormatItalicIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title='Underline'>
            <IconButton
              onClick={e => {
                e.preventDefault()
                editor.chain().focus().toggleUnderline?.().run() // Check if underline is supported
              }}
              color={editor.isActive('underline') ? 'primary' : 'default'}
            >
              <FormatUnderlinedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title='Strikethrough'>
            <IconButton
              onClick={e => {
                e.preventDefault()
                editor.chain().focus().toggleStrike().run()
              }}
              color={editor.isActive('strike') ? 'primary' : 'default'}
            >
              <StrikethroughSIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title='Bullet List'>
            <IconButton
              onClick={e => {
                e.preventDefault()
                editor.chain().focus().toggleBulletList().run()
              }}
              color={editor.isActive('bulletList') ? 'primary' : 'default'}
            >
              <FormatListBulletedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title='Ordered List'>
            <IconButton
              onClick={e => {
                e.preventDefault()
                editor.chain().focus().toggleOrderedList().run()
              }}
              color={editor.isActive('orderedList') ? 'primary' : 'default'}
            >
              <FormatListNumberedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title='Clear Formatting'>
            <IconButton
              onClick={e => {
                e.preventDefault()
                editor.chain().focus().unsetAllMarks().run()
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </div>

        <EditorContent css={editorStyles} editor={editor} />
      </div>


      {helperText && !(form.touched[field.name] && form.errors[field.name]) &&
        <div>
          {helperText}
        </div>
      }


      {/* Formik Error Handling */}
      {form.touched[field.name] && form.errors[field.name] && (
        <div css={styles.error}>
          {form.errors[field.name]}
        </div>
      )}
    </div>
  )
}



export default FormikTipTap





