import React, {useState, useMemo} from 'react'

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {useIsMounted} from '../../hooks'

import {Virtuoso} from 'react-virtuoso'

import BandCard from './BandCard'
import TrackCard from './TrackCard'
import VenueCard from './VenueCard'
import ArtistCard from './ArtistCard'
import PerformanceCard from './PerformanceCard'

import {ThreeDotProgress} from '../generics'



const PerformanceItemContainer = styled.div`
  width: 100%;
  height: 150px;
  box-sizing: border-box;
`

const ItemContainer = styled.div`
  height: 100px;
  width: 100%;
  box-sizing: border-box;
`

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`



const styles = {
  root: css`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    &::-webkit-scrollbar {
      width: 1px; 
      display: none;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  `,
  footer: css`
    padding: 1rem;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
  `
}



const isEqual = (prevProps, nextProps) => (
  prevProps.hasNextPage === nextProps.hasNextPage &&
  prevProps.cards.length === nextProps.cards.length &&
  prevProps.fetchMoreItems === nextProps.fetchMoreItems
)


const CardListMobile = props => {

  const isMounted = useIsMounted()
  
  const [isFetching, setIsFetching] = useState(false)


  const {type, cards, hasNextPage, fetchMoreItems} = props


  const itemCount = cards.length
  const isEventType = (type === 'performance')



  const Cards = useMemo(() => (
    cards.map(card => {
      switch (card.type) {
      case 'artist':
         return <ArtistCard artist={card} />
      case 'band':
        return <BandCard band={card} />
      case 'track':
        return <TrackCard track={card} />
      case 'venue':
        return <VenueCard venue={card} />
      case 'performance':
        return <PerformanceCard performance={card} />
      default:
        return null
      }
    })
  ), [cards]) 




  const Footer = () => (
    <div css={styles.footer}>
      {isFetching && <ThreeDotProgress radius={4} />}
    </div>
  )




  return (
    <Virtuoso
      css={styles.root}
      style={{height: '100%', width: '100%'}}
      totalCount={itemCount}
      fixedItemHeight={isEventType ? 150 : 100}
      itemContent={index => Cards[index]}
      computeItemKey={index => cards[index].id}
      increaseViewportBy={{
        top: isEventType ? 150 : 100,
        bottom: isEventType ? 150 : 100
      }}
      useWindowScroll
      endReached={ index => {
        if (hasNextPage) {
          setIsFetching(true)
          fetchMoreItems({index, setIsFetching, isMounted}) 
        }
      }}
      components={{
        List: ListContainer,
        Item: isEventType ? PerformanceItemContainer : ItemContainer,
        Footer: hasNextPage ? Footer : null
      }}
    />
  )
}



export default React.memo(CardListMobile, isEqual)


