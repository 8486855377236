import {createSelector} from 'reselect'
import {get, pick, map} from 'lodash'



const emptyArray = []
const emptyObject = {}


const selectBandIds = state => state.bands.byId
const selectArtistIds = state => state.artists.byId
const selectProductIds = state => state.products.byId
const selectPerformances = state => state.performances.byId


const selectVenueId = (state, id) => {
  return get(state.venues.byId[id], 'id', false)
}

const selectVenueAttributesById = (state, id) => {
  return get(state.venues.byId[id], 'attributes')
}

const selectVenuePerformances = (state, id) => {
  return get(state.venues.byId[id], ['relationships', 'performances'], emptyArray)
}

const selectVenuePerformers = (state, id) => {
  return get(state.venues.byId[id], ['relationships', 'performers'], emptyArray)
}


const selectVenueProducts = (state, id) => {
  return get(state.venues.byId[id], ['relationships', 'products'], emptyArray)
}


export const selectVenueFetchedAssociations = (state, id) => {
  return get(state.venues.byId[id], 'fetched', emptyArray)
}

const selectVenueLinks = (state, id, association) => {
  return get(state.venues.byId[id], ['links', association], emptyObject)
}

export const selectVenues = state => state.venues.byId


export const makeGetVenueById = () => {
  return createSelector(
    [selectVenueId, selectVenueAttributesById], 
    (id, attributes) => ({ id, attributes, type: 'venue' })
  )
}


export const makeGetVenuePerformances = () => {
  return createSelector([selectVenuePerformances, selectPerformances],
    (venuePerformances, performances) => {
      const performanceIds = map(venuePerformances, 'id')

      return Object.values(pick(performances, performanceIds))
    },
    {
      memoizeOptions: {
        equalityCheck: (a, b) => {
          if (typeof a === 'object') return Object.keys(a).length === Object.keys(b).length
          if (Array.isArray(a)) return a.length === b.length

          return false
        }
      }
    }
  )
}


export const makeGetVenuePerformers = () => {
  return createSelector(
    [selectVenuePerformers, selectBandIds, selectArtistIds],
    (venuePerformers, bands, artists) => { 
      const performersArray = venuePerformers || emptyArray
      const performers = performersArray.reduce((acc, performer) => {
        if (performer.type === 'artist') {
          acc.push(artists[performer.id])
        }
        if (performer.type === 'band') {
          acc.push(bands[performer.id])
        }
        return acc
      }, [])

      return performers
    },
    {
      memoizeOptions: {
        equalityCheck: (a, b) => {
          if (typeof a === 'object') return Object.keys(a).length === Object.keys(b).length
          if (Array.isArray(a)) return a.length === b.length

          return false
        }
      }
    }
  )
}


export const makeGetVenueProducts = () => {
  return createSelector([selectVenueProducts, selectProductIds],
    (venueProducts, products) => {
      const productIds = map(venueProducts, 'id')

      return Object.values(pick(products, productIds))
    }, 
    {
      memoizeOptions: {
        equalityCheck: (a, b) => {
          if (typeof a === 'object') return Object.keys(a).length === Object.keys(b).length
          if (Array.isArray(a)) return a.length === b.length

          return false
        }
      }
    }
  )
}


export const makeGetVenueLinks = () => {
  return createSelector([selectVenueLinks], (venueLinks) => venueLinks)
}


