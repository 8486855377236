import React from 'react'

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import {connect, useDispatch} from 'react-redux'

import {useNavigate} from 'react-router-dom'

import { 
  makeGetArtistTracks, 
  makeGetArtistFetchedAssociations 
} from '../../selectors'


import {show} from '../../apis'

import {
  convertSpotifyTracksToCkordFormat,
  convertAppleMusicTracksToCkordFormat
} from '../../helpers'


import { 
  pause, 
  queue,
  enqueue,
  showAlert,
  addTracksToArtist,
  getQueueAndPlayTracks
} from '../../actions'


import { getImageOfSize } from '../../utils'
import useMediaQuery from '@mui/material/useMediaQuery'
import ArtistCardButtons from './ArtistCardButtons'
import Typography from '@mui/material/Typography'
import PlayIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import { Image } from '../generics'



const desktopStyles = {
  card: css`
    width: 200px;
    height: 330px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    transition: 0.3s;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px;
  `,
  imageContainer: css`
    width: 200px;
    height: 200px;
    border-radius: 6px 6px 0px 0px;
    position: relative;
    overflow: hidden;
  `,
  image: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
  iconContainer: css`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;
    z-index: 10;
  `,
  iconSubContainer: css`
    width: 100%;
    height: 100%;
    position: relative;
  `,
  iconCircle: css`
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.6);
  `,
  infoContainer: css`
    padding: 12px 12px 0px 12px;
    flex-grow: 1; 
    min-width: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
  `,
  title: css`
    width: 100%;
    max-width: 100%;
    font-weight: 600;
    font-size: 1.2rem;
    padding-right: 5px;
    margin-bottom: 2px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
  `,
  buttons: css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 52px;
    padding: 4px
  `,
  link: css`
    text-decoration: none;
    color: inherit;
  ` 
}


const mobileStyles = {
    card: css`
    width: 100%;
    margin: 5px 0px;
    padding: 10px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    transition: 0.3s;
  `,
  imageContainer: css`
    width: 80px;
    height: 80px;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px;
  `,
  image: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
  iconContainer: css`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;
    z-index: 10;
  `,
  iconSubContainer: css`
    width: 100%;
    height: 100%;
    position: relative;
  `,
  iconCircle: css`
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.6);
  `,
  infoContainer: css`
    padding: 12px;
    flex-grow: 1; 
    min-width: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
  `,
  title: css`
    width: 100%;
    max-width: 100%;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1;
    padding-right: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
  `,
  buttons: css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 52px;
    padding: 4px
  `,
  link: css`
    text-decoration: none;
    color: inherit;
  `
}




const ArtistCard = props => {

  const dispatch = useDispatch()

  const navigate = useNavigate()
  const isExtraSmallScreen = useMediaQuery(theme => theme.breakpoints.only('xs'))
  const { 
    artist,
    tracks,
    isLoaded,
    isPlaying,
    pause,
    getQueueAndPlayTracks,
    addTracksToArtist,
    queue,
    enqueue,
    showAlert,
  } = props
  
  const { name, trackCount, image, externalImageUrl, links} = artist.attributes
  const imageURL = getImageOfSize(artist.attributes, 'small') || image
  const href = process.env.REACT_APP_BASE_URL + '/artists/' + artist.id


  const styles = isExtraSmallScreen ? mobileStyles : desktopStyles


  const icons = {
    play:  <PlayIcon  color='secondary' fontSize={ isExtraSmallScreen ? 'medium' : 'large' } />,
    pause: <PauseIcon color='secondary' fontSize={ isExtraSmallScreen ? 'medium' : 'large' } />
  }

  const icon = (isPlaying ? 'pause' : 'play')

  function handleClick(e) {
    e.preventDefault()
    e.stopPropagation()

    navigate('/artists/' + artist.id)
  }



  function handlePlayPress(e) {
    e.preventDefault()
    e.stopPropagation()

    if (icon === 'play') {
      if (isLoaded) {
        queue(tracks, artist.id, 'artist')
      } else { 
        fetchAndPlayAudio()
        fetchAppleTracks()
      }
    } else {
      pause()
    }
  }


  function fetchAndPlayAudio() {
    const url = '/artists/' + artist.id + '/tracks'

    getQueueAndPlayTracks(url, artist.id, 'artist').then(response => {
      if (response === 'error') {
        showAlert('Something went wrong loading this artist\'s tracks', 'error')
      }
    })
  }



  function fetchSpotifyTracks() {
    if (!links.spotify) return

    const spotifyArtistID = links.spotify.split('/').reverse()[0]

    if (!spotifyArtistID) return


    show('/services/spotify/artists/' + spotifyArtistID + '/tracks').then(response => {
      const data = response.data

      // Format the Spotify tracks to match the Ckord tracks
      const newTracks = convertSpotifyTracksToCkordFormat(data, artist.id, 'Artist', links.spotify)


      // Update the store
      dispatch({type: 'TRACKS_INDEX_SUCCESS', payload: {data: newTracks}})
      addTracksToArtist(artist.id, newTracks)

      // Add the tracks to the player queue
      enqueue(newTracks, artist.id, 'artist')
    })
    .catch(error => console.log(error))
  }



  function fetchAppleTracks() {
    if (!links.apple) return

    const appleMusicArtistID = links.apple.split('?')[0].split('/').reverse()[0]

    if (!appleMusicArtistID) return


    show('/services/apple-music/artists/' + appleMusicArtistID + '/tracks').then(response => {
      const data = response.data

      // Format the Spotify tracks to match the Ckord tracks
      const newTracks = convertAppleMusicTracksToCkordFormat(data, artist.id, 'Artist', links.apple)


      // Update the store
      dispatch({type: 'TRACKS_INDEX_SUCCESS', payload: {data: newTracks}})
      addTracksToArtist(artist.id, newTracks)

      // Add the tracks to the player queue
      enqueue(newTracks, artist.id, 'artist')
    })
    .catch(error => console.log(error))
  }




  return (
    <div css={ styles.card }>
      <a css={ styles.link } href={ href } onClick={ e => e.preventDefault() }>
        <div css={ styles.imageContainer } onClick={ handleClick }>
        { (trackCount > 0 || links.apple) &&  
            <div css={ styles.iconContainer }>
              <div css={ styles.iconSubContainer }>
                <div css={ styles.iconCircle } onClick={ handlePlayPress }>
                  { icons[icon] }
                </div>
              </div>
            </div>
          }

          <Image css={ styles.image } src={ imageURL || externalImageUrl } alt='artist' />
        </div>
      </a>

        <div css={ styles.infoContainer } onClick={ handleClick }>
          <a css={ styles.link } href={ href } onClick={ e => e.preventDefault() }>
            <Typography css={ styles.title }>
              { name }
            </Typography>
            
            <Typography noWrap>
              Artist
            </Typography>
          </a>
        </div>

        <div css={ styles.buttons }>
          <ArtistCardButtons 
            artistId={ artist.id } 
            name={ name }
            imageURL={ imageURL }
          />
        </div>
    </div>
  )
}





const makeMapStateToProps = () => {
  const getArtistTracks = makeGetArtistTracks()
  const getFetchedAssociations = makeGetArtistFetchedAssociations()
  

  const mapStateToProps = (state, props) => {
    const id = props.artist.id
    const tracks = getArtistTracks(state, id)
    const isLoaded = getFetchedAssociations(state, id).includes('tracks')

    const callerID = state.player.callerID
    const isPlaying = state.player.isPlayRequested && callerID === id

    return {
      tracks: tracks,
      isLoaded: isLoaded,
      isPlaying: isPlaying
    }
  }

  return mapStateToProps
}


const actions = { 
  pause,
  addTracksToArtist,
  getQueueAndPlayTracks,
  queue,
  enqueue,
  showAlert
}

export default connect(makeMapStateToProps, actions)(ArtistCard)

