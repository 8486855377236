import {useState, useEffect} from 'react'
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'

import ExpandingText from '../ExpandingText'

import DOMPurify from 'dompurify'



const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 30px;
  `,
  title: css`
    font-weight: 500;
    font-size: 0.9rem;
  `
}


// parseAppleCSS: Takes a CSS string (from the <style> block) and
// returns an object mapping className -> { bold, italic, underline } booleans.
function parseAppleCSS(cssText) {
  const classMap = {}
  // A naive regex for rules like: .s1 { font-weight: bold; font-style: italic; ... }
  const regex = /\.([a-zA-Z0-9_-]+)\s*\{([^}]+)\}/g
  let match
  while ((match = regex.exec(cssText)) !== null) {
    const cls = match[1]  // e.g. "s1"
    const body = match[2].toLowerCase()

    classMap[cls] = {
      bold: body.includes('font-weight: bold'),
      italic: body.includes('font-style: italic'),
      underline: body.includes('text-decoration: underline'),
      strikethrough: body.includes('line-through')
    }
  }
  return classMap
}


function transformAppleHTML(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html')

  const styleTag = doc.querySelector('style')
  if (!styleTag) return htmlString; // no style => no transform

  const cssText = styleTag.textContent;
  const classMap = parseAppleCSS(cssText)

  Object.entries(classMap).forEach(([cls, {bold, italic, underline, strikethrough}]) => {
    doc.querySelectorAll(`.${cls}`).forEach((el) => {
      let content = el.innerHTML
      if (bold) {
        content = `<strong>${content}</strong>`
      }
      if (italic) {
        content = `<em>${content}</em>`
      }
      if (underline) {
        content = `<u>${content}</u>`
      }
      if (strikethrough) {
        content = `<s>${content}</s>`
      }
      el.innerHTML = content;
      el.removeAttribute('class')
    });
  });

  styleTag.remove(); // optional
  return doc.documentElement.outerHTML;
}


const InfoCategory = props => {
  const {title, text} = props


  const [transformedHTML, setTransformedHTML] = useState('')


  useEffect(() => {
    const result = transformAppleHTML(text)
    setTransformedHTML(DOMPurify.sanitize(result))
  }, [text])




  return (
    <div css={styles.root}>
      {title && 
        <h4 css={styles.title}>{title}</h4>
      }

      {text &&
        <ExpandingText text={transformedHTML} component='pre'>
          {text}
        </ExpandingText>
      }
    </div>
  )
}


export default InfoCategory
